<template>
  <div class="w-full flex flex-col">
    <div class="my-2 z-10 h-auto w-full">
      <h3 class="ejx-h3 mb-4">
        Create your WebXR <span class="font-bold">Play Link</span> below:
      </h3>
    </div>
    <div class="my-4 w-full relative">
      <EJPopover v-model:open="showExamples">
        <template #reference>
          <div class="flex items-stretch gap-4 w-full">
            <EJInput
              v-model:value="url"
              class="flex-1 min-h-12"
              aria-label="URL to play with WebXR"
              :schemas="URL_SCHEMA"
              :no-padding="true"
              placeholder="https://"
              @validator-change="handleValidatorChange"
            />
            <template v-if="windowWidth !== Infinity">
              <EJButton
                v-if="windowWidth < 620"
                type="primary"
                variant="icon"
                aria-label="Show Examples"
                class="h-full gap-2 font-bold"
                @click.prevent="handleExamplesClick"
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="-rotate-90"
                >
                  <path
                    d="M6.38086 9.94829L1.46491 6.3033L1.46491 4.70861L6.38086 1.06362"
                    class="EJButton_Arrow"
                    stroke-width="1.25545"
                  />
                </svg>
              </EJButton>
              <EJButton
                v-else
                type="primary"
                aria-label="Show Examples"
                class="hidden md:flex h-full gap-2 font-bold"
                @click.prevent="handleExamplesClick"
              >
                Examples
              </EJButton>
            </template>
          </div>
        </template>
        <EJListbox
          v-model:value="exampleValue"
          :options="EXAMPLES"
          class="max-h-[400px] overflow-y-scroll mt-2"
          @value-changed="showExamples = false"
        >
          <template #element="defaultProps">
            <p class="ejx-text-sm flex justify-between items-center">
              {{ defaultProps.value.label }}
              <img
                :src="COLLECTION_LOGO_LOOKUP[toExampleModel(defaultProps.value).collection]"
                class="inline h-8 group-[.is-invert]:filter group-[.is-invert]:invert"
              >
            </p>
          </template>
        </EJListbox>
      </EJPopover>
      <template v-if="hasError && hasInputChanged">
        <p class="text-red-500">
          {{ errorMessages[0] }}
        </p>
      </template>
    </div>
    <div class="my-2 flex flex-col items-center md:flex-row md:items-stretch gap-4 md:gap-8">
      <div class="flex flex-col">
        <div
          class="bg-white outline outline-2 outline-black dark:outline-white w-[230px] h-[230px] sm:w-[315px] sm:h-[315px]"
        >
          <QRDisplayer
            class="h-full"
            ref="qrDisplayer"
            :url="finalUrl"
            :logo="EyeJackQRCode.src"
            :logo-margin="25"
            :valid="!hasError"
            :size="315"
            @ready="$event => ready = $event"
          />
        </div>
        <EJButton
          v-if="windowWidth <= 768"
          class="w-auto mx-auto md:mx-0 uppercase"
          variant="link"
          :disabled="hasError"
          @click="handleDownload"
        >
          Download QR Code
        </EJButton>
      </div>
      <div class="flex flex-col justify-end gap-2 md:gap-4">
        <EJButton
          v-if="isShareSupported && windowWidth > 1000"
          class="w-auto"
          :disabled="hasError"
          @click="handleShare"
        >
          Share&nbsp;<span class="font-bold">Play Link</span>
        </EJButton>
        <CopyTextButton
          v-else
          class="w-auto"
          :text="finalUrl"
          :disabled="hasError"
          @copied="handleCopied"
        >
          Copy&nbsp;<span class="font-bold">Play Link</span>
        </CopyTextButton>
        <EJButton
          class="block md:hidden"
          :disabled="hasError"
          @click="handlePlay"
        >
          Play
        </EJButton>
      </div>
    </div>
    <EJButton
      v-if="windowWidth > 768"
      class="w-fit uppercase"
      variant="link"
      :disabled="hasError"
      @click="handleDownload"
    >
      Download QR Code
    </EJButton>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useShare, useWindowSize } from '@vueuse/core'
import { useGtm } from '@gtm-support/vue-gtm';
// Components
import EJInput from './generics/EJInput.vue';
import EJPopover from './generics/EJPopover';
import EJListbox, { type EJListBoxOption } from './generics/EJListbox.vue'
import EJButton from './generics/EJButton.vue'
import QRDisplayer from './generics/QRDisplayer.vue'
import CopyTextButton from './generics/CopyTextButton.vue';
import z from 'zod';

import EyeJackQRCode from '../assets/eyejack_qr_logo.png';


const ready = ref(false)

const gtm = useGtm();

type ExampleModel = EJListBoxOption & {
  image: string;
  collection: 'three' | 'immersive web' | 'aframe' | 'babylonjs';
}
const toExampleModel = (v: EJListBoxOption) => v as ExampleModel

// Assets
import LogoThreeImg from '../assets/images/logo-three.png';
import LogoAframeImg from '../assets/images/logo-aframe.png';
import LogoBabylonImg from '../assets/images/logo-babylon-2.png';
import LogoImmersiveWebImg from '../assets/images/logo-webxr.png';

const COLLECTION_LOGO_LOOKUP: Record<ExampleModel['collection'], string> = {
  'three': LogoThreeImg.src,
  'aframe': LogoAframeImg.src,
  'babylonjs': LogoBabylonImg.src,
  'immersive web': LogoImmersiveWebImg.src,
}

const EXAMPLES: ExampleModel[] = [
  {
    label: 'Immersive AR Session',
    value: 'https://immersive-web.github.io/webxr-samples/immersive-ar-session.html',
    collection: 'immersive web',
  },
  {
    label: 'Bouncing Band',
    value: 'https://bouncing.band',
    collection: 'immersive web',
  },
  {
    label: 'XR Dinosaurs',
    value: 'https://xrdinosaurs.com',
    collection: 'immersive web',
  },
]

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
const URL_SCHEMA = z.string()
  .nonempty('Please enter a URL.')
  .regex(URL_REGEX, 'Not a valid url.')
  .regex(/^(https)/gi, 'Url must start with \'https://\'');
const url = ref(EXAMPLES[0].value);

const hasInputChanged = ref(false);
const errorMessages = ref<string[]>([]);
const hasError = computed((() => errorMessages.value.length > 0));
const handleValidatorChange = (_: boolean, errors: z.ZodError<string>[]) => {
  errorMessages.value = errors.map(e => e.issues).flat().map(e => e.message);
}
watch(url, () => {
  hasInputChanged.value = true;
})


const sanitisedUrl = computed(() => encodeURIComponent(url.value));
const finalUrl = computed(() => {
  if (import.meta.env.SSR) return 'https://play.eyejack.xyz/';
  // Remove last `/` if present
  return `${import.meta.env.PUBLIC_SITE_URL}/link/?url=${sanitisedUrl.value}`;
})
const qrDisplayer = ref<typeof QRDisplayer>()

const { width: windowWidth } = useWindowSize();
const { isSupported: isShareSupported, share: shareUrl } = useShare();
const handleShare = () => {
  shareUrl({ url: finalUrl.value, title: 'Play this WebXR experience with EyeJackX' }).then(() => triggerGTMEvent('copy-btn-click', 'home-page', 'click', 'Copy URL button click trigger')).catch((error) => {
    console.error(`QRGenerator: Error sharing url ${error}`)
  });
}
const handleCopied = () => {
  triggerGTMEvent('copy-btn-click', 'home-page', 'click', 'Copy URL button click trigger');
}

const { isSupported: isDownloadSupported, share: shareQR } = useShare({ title: 'WebXR Player QR Code - EyejackX', text: 'Here\'s a QR code to play the WebXR experience on any device', files: [new File([new Blob()], 'Test file.png')] })
const handleDownload = async () => {
  if (isDownloadSupported.value && qrDisplayer.value) {
    const canvas: HTMLCanvasElement|undefined = qrDisplayer.value.getCanvas();
    if (!canvas) {
      console.error('Error: Can\'t get canvas to generate blob.')
      return;
    }
    const blob: Blob|null = await new Promise(res => canvas.toBlob(blob => res(blob), 'image/png', 90));
    if (blob) {
      shareQR({
        title: 'WebXR Play Link',
        files: [new File([blob], 'EyeJackX Play Code.png', { type: blob.type })],
      }).then(() => triggerGTMEvent('download-btn-click', 'home-page', 'click', 'Download QR code click trigger'))
    } else {
      console.error('Error: Can\'t generate canvas blob for QRCode.')
    }
  } else {
    if (qrDisplayer.value) {
      qrDisplayer.value.download();
      triggerGTMEvent('download-btn-click', 'home-page', 'click', 'Download QR Code click trigger');
    }
  }
}

const exampleValue = ref<ExampleModel | undefined>();
watch(exampleValue, (exampleUrl) => {
  if (exampleUrl) {
    url.value = exampleUrl.value;
  }
})
const showExamples = ref(false);
const handleExamplesClick = () => {
  showExamples.value = !showExamples.value;
}

const triggerGTMEvent = (event: string, category: string, action: string, label?: string) => {
  gtm && gtm.trackEvent({
    event,
    category,
    action,
    label,
    value: 5000,
    noninteraction: false,
  });
}

const handlePlay = () => {
  window.open(url.value, '_blank');
}

</script>

<style></style>
