<template>
  <div class="QRDisplayer relative p-2">
    <div ref="containerEl" class="relative w-full h-full">
      <img class="absolute inset-0 w-full h-full" :src="dataUri" />
    </div>
    <div :class="{
      'absolute inset-0 w-full h-full bg-white opacity-0 transition-opacity pointer-events-none flex justify-center items-center': true,
      'opacity-100': !valid,
    }">
      <img :src="logo" class="transform origin-center scale-50" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, shallowRef, toRef, computed } from 'vue';
import { refThrottled } from '@vueuse/core';
import { QRCodeCanvas } from '@akamfoad/qrcode';

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  valid: {
    type: Boolean,
    default: true,
  },
  size: {
    type: Number,
    default: 256
  },
  renderSize: {
    type: Number,
    default: 2048
  },
  logo: {
    type: String,
    default: undefined
  },
  logoMargin: {
    type: Number,
    default: 0,
  }
})

const emit = defineEmits<{
  (e: 'ready', ready: boolean): void
}>();

const containerEl = shallowRef<HTMLDivElement>();
const canvasEl = shallowRef<HTMLCanvasElement>();

const qr = shallowRef(new QRCodeCanvas(props.url, {
  level: 'Q',
  image: {
    source: props.logo,
    width: '20%',
    height: '20%',
    x: 'center',
    y: 'center',
  },
}));
const throttledUrl = refThrottled(toRef(props, 'url'), 500);
const dataUri = ref<string|undefined>(undefined);
watchEffect(() => {
  qr.value.setValue(throttledUrl.value);
  dataUri.value = undefined;
  const result = qr.value.toDataUrl();
  if (result instanceof Promise) {
    result.then(result => dataUri.value = result);
  } else {
    dataUri.value = result;
  }
});

const download = async () => {
  const { downloadCanvas } = await import('../../utils/download')

  let canvas: HTMLCanvasElement | Promise<HTMLCanvasElement> | null = qr.value.getCanvas();
  if (canvas instanceof Promise) canvas = await canvas;

  if (!canvas) throw new Error("Could not download QR code because could not get canvas.");

  downloadCanvas(canvas);
}
defineExpose({
  download,
  getCanvas() {
    return containerEl.value?.firstElementChild as HTMLCanvasElement;
  }
})
</script>

<style lang="scss">
.QRDisplayer canvas,
.QRDisplayer svg {
  width: 100%;
  height: 100%;
}
</style>
