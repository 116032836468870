<template>
  <div 
    :class="{
      'relative EJInput outline outline-2 flex items-center focus-within:z-10': true,
      'dark:bg-black dark:hover:bg-gray-900 dark:outline-white': true,
      'bg-white hover:bg-gray-100 outline-black': true,
      'bg-gray-200 dark:bg-gray-800 outline-2': focused,
    }"
    @click="handleParentClick"
  >
    <div
      v-if="slots.prefix"
      :class="{
        'pl-2': variant === 'medium',
        'pl-4': variant === 'large',
      }"
    >
      <slot name="prefix" />
    </div>
    <input 
      ref="inputEl" 
      :class="{
        'bg-transparent outline-none w-full ejx-text-sm h-full': true,
        'p-2': variant === 'medium',
        'px-4 py-3': variant === 'large',
        'pl-0': slots.prefix,
        'text-xl': variant === 'large',
      }" 
      :aria-label="ariaLabel" 
      :value="props.value"
      :placeholder="placeholder"
      @input="handleInputChange"
      @focusin="focused = true"
      @focusout="focused = false"
    >
    <slot name="suffix" />
  </div>
</template>

<script setup lang="ts">
import { ref, type PropType, onMounted, watchEffect, useSlots } from 'vue';
import { type SafeParseError, type ZodError, type ZodString } from 'zod';
import { fromMaybeArray } from '../../utils/array';

const props = defineProps({
  variant: {
    type: String as PropType<'medium' | 'small' | 'large'>,
    default: 'medium',
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined,
  },
  ariaLabel: {
    type: String,
    required: false,
    default: undefined,
  },
  value: {
    type: String,
    required: false,
    default: undefined,
  },
  schemas: {
    type: [Array, Object] as PropType<ZodString>,
    default: undefined,
  },
})

const slots = useSlots();

const focused = ref(false);

const emit = defineEmits<{
  (e: 'update:value', value: string): void,
  (e: 'validatorChange', ok: boolean, messages: ZodError<string>[]): void,
}>();

const inputEl = ref<HTMLInputElement>();

const handleInputChange = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  emit('update:value', value);
}

const validate = (value?: string) => {
  if (props.schemas) {
    const validators = fromMaybeArray(props.schemas);
    const results = validators.map((schema) => {
      return schema.safeParse(value ?? props.value)
    }).filter((res): res is SafeParseError<string> => !res.success).map((res) => res.error);
    emit('validatorChange', results.length === 0, results);
  }
}
onMounted(() => {
  validate()
})
watchEffect(() => {
  validate(props.value);
})

const handleParentClick = (e: Event) => {
  if (inputEl.value) {
    inputEl.value.focus();
  }
}

</script>
