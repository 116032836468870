<template>
  <div class="flex overflow-y-auto flex-wrap bg-black outline-black EJListbox outline outline-2">
    <div 
      v-for="(option, index) in options"
      :key="option.label"
      :class="{
        'EJListbox_Element': true,
        'p-1 cursor-pointer select-none': true,
        'bg-white hover:bg-gray-200 click:bg-gray-300': option.value !== value?.value,
        'group is-invert bg-black hover:bg-gray-900 click:bg-gray-800 text-white': option.value === value?.value,
      }"
      :style="{
        width: `${100 / columns}%`,
      }" 
      @click="handleOptionClick(option)"
    >
      <slot
        name="element"
        :value="option"
        :index="index"
      >
        {{ option.label }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { type PropType } from 'vue';

export type EJListBoxOption = {
  label: string;
  value: string;
};

const props = defineProps({
  options: {
    type: Array as PropType<EJListBoxOption[]>,
    required: true,
  },
  value: {
    type: Object as PropType<EJListBoxOption | undefined>,
    required: false,
    default: undefined,
  },
  columns: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits<{
  (e: 'update:value', value: EJListBoxOption): void,
  (e: 'valueChanged', value: EJListBoxOption): void,
}>();
const value = useVModel(props, 'value', emit);

const handleOptionClick = (option: EJListBoxOption) => {
  value.value = option;
  emit('valueChanged', option);
}


</script>
