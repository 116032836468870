<template>
  <EJButton
    class="flex relative items-center"
    :variant="variant"
    @click="handleButtonClick"
  >
    <svg
      :class="{
        'absolute left-2 scale-150 transition-all duration-200 stroke-lime-600': true,
        'top-1/2 -translate-y-1/2': props.variant !== 'link',
        '-translate-y-full': props.variant === 'link',
      }"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="50"
      :style="{
        strokeDashoffset: copied ? 0 : -50,
      }"
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
    <slot />
  </EJButton>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core';
import { type PropType } from 'vue';
import EJButton from './EJButton.vue';
type EJButtonBaseProps = InstanceType<typeof EJButton>['$props'];
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  variant: {
    type: String as PropType<EJButtonBaseProps['variant']>,
    default: undefined,
  }
});

const emit = defineEmits<{
  (e: 'copied'): void
}>();

const { copy, copied } = useClipboard({});
const handleButtonClick = () => {
  copy(decodeURIComponent(props.text)).then(() => {
    emit('copied');
  });
}
</script>
