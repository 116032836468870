import {
  flip,
  type Middleware,
  offset,
  type Placement,
  shift,
  size,
  useFloating,
} from '@floating-ui/vue';
import { onClickOutside, onKeyStroke, useVModel } from '@vueuse/core';
import {
    computed,
  defineComponent,
  type MaybeRef,
  type PropType,
  ref,
  type StyleValue,
  Teleport,
  type TeleportProps,
  toRef,
  unref,
  watchEffect,
} from 'vue';

export default defineComponent({
  props: {
    reference: {
      type: Object as PropType<MaybeRef<HTMLElement>>,
      default: undefined,
    },
    to: {
      type: [String, Object] as PropType<TeleportProps['to']>,
      default: 'body',
    },
    strategy: {
      type: String as PropType<'absolute' | 'fixed'>,
      default: 'absolute',
    },
    open: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom',
    },

    shouldFlip: {
      type: Boolean,
      default: true,
    },
    shouldShift: {
      type: Boolean,
      default: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  emits: {
    'update:open': null,
  },
  setup(props, ctx) {
    const referenceEl = ref<HTMLElement>();
    const resolvedReference = computed(() => {
      return referenceEl.value ?? unref(props.reference);
    });
    const floatingEl = ref<HTMLDivElement>();

    const open = useVModel(props, 'open', ctx.emit);

    onClickOutside(floatingEl, () => {
      open.value = false;
    });
    onKeyStroke('Escape', () => {
      console.log('Escape pressed');
      if (open.value) open.value = false;
    });

    watchEffect(() => {
      if (props.reference && ctx.slots.reference) {
        console.error(
          'EJPopover: Do not use the `reference` slot as well as the `reference prop.  Use one or the other.',
        );
      } else if (!props.reference && !ctx.slots.reference) {
        console.error(
          'EJPopover: No reference provided.  Please use the `reference` slot OR the `reference prop.',
        );
      }
    });

    const referenceWidth = ref(0);
    const { x, y, strategy, isPositioned } = useFloating(
      resolvedReference,
      floatingEl,
      {
        strategy: toRef(props, 'strategy'),
        placement: toRef(props, 'placement'),
        open: toRef(props, 'open'),
        middleware: computed(() => {
          const middleware: Middleware[] = [size({
            apply({ rects }) {
              referenceWidth.value = rects.reference.width;
            },
          })];
          if (props.shouldShift) middleware.push(shift());
          if (props.shouldFlip) middleware.push(flip());
          if (props.offset) middleware.push(offset(props.offset));
          return middleware;
        }),
      },
    );

    watchEffect(() => {
      console.log(x.value, y.value, isPositioned.value);
    });

    const floatingStyles = computed<StyleValue>(() => ({
      position: strategy.value as 'absolute' | 'fixed',
      left: `${x.value}px`,
      top: `${y.value}px`,
      width: `${referenceWidth.value}px`,
      zIndex: props.open ? 9999 : 0,
    } as StyleValue));

    return () => (
      <div>
        {ctx.slots.reference && (
          <div ref={referenceEl}>
            {ctx.slots.reference()}
          </div>
        )}
        {props.open && (
          <Teleport to={props.to}>
            <div
              ref={floatingEl}
              class="EJPopover"
              style={floatingStyles.value}
            >
              {ctx.slots.default && ctx.slots.default()}
            </div>
          </Teleport>
        )}
      </div>
    );
  },
});
