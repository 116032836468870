
/**
 * Converts a maybe array (var or array of vars) into an array.
 * @template T - 
 * @param maybeArray - 
 * @returns 
 */
export const fromMaybeArray = <T,>(maybeArray: T|T[]): T[] => {
  if (Array.isArray(maybeArray)) { return maybeArray }
  return [maybeArray];
}
